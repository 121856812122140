
import { Component, Vue, Watch } from "vue-property-decorator";
import { copyToClipboard, Notify } from "quasar";
//import menu from "@/components/Common/Web/menu_maipu.json";
import Buscador from "@/components/Common/Web/Buscador.vue";
@Component({
  components: {
    Buscador
  }
})
export default class HeaderComponent extends Vue {
  private text = "";
  private left = false;
  private win = window;
  private menu = "";

  private mounted() {
    this.getRuntimeConfig();
  }

  private llamar(data: any) {
    const numero = data;
    window.open("tel:" + numero);
  }

  private async getRuntimeConfig() {
    const runtimeConfig = await fetch("/menu_maipu.json");
    this.menu = await runtimeConfig.json();
  }
  private copiarClipboard(texto: any) {
    copyToClipboard(texto)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado el Número de Teléfono.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    console.log(nombreWeb);
    if (nombreWeb == "tramites-de-salud") {
      this.routerGo("TramitesSalud");
    } else if (nombreWeb == "nuestra-red-de-salud-maipu") {
      this.routerGo("NuestraRed");
    } else if (nombreWeb == "quienes-somos") {
      this.routerGo("QuienesSomos");
    } else if (nombreWeb == "informacion-al-paciente") {
      this.routerGo("InformacionPaciente");
    }
    else if (nombreWeb == "prestaciones-de-salud-y-programas") {
      this.routerGo("PrestacionSaludProgramas");
    }
    else if (nombreWeb == "inscribete-en-tu-cesfam") {
      this.routerGo("InscribeteCesfam");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private routerGoMenu(name: string, nombre_web: string) {
    if (nombre_web && nombre_web != "") {
      if (
        this.$router.currentRoute.name == name &&
        this.$router.currentRoute.params.nombre &&
        this.$router.currentRoute.params.nombre == nombre_web
      ) {
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == name) {
          if (
            this.$router.currentRoute.params.nombre &&
            this.$router.currentRoute.params.nombre != nombre_web
          ) {
            this.$router
              .push({ name: name, params: { nombre: nombre_web } })
              .catch(err => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$router
              .push({ name: name, params: { nombre: nombre_web } })
              .catch(err => {
                console.log(err);
              });
          }
        } else {
          this.$router
            .push({ name: name, params: { nombre: nombre_web } })
            .catch(err => {
              console.log(err);
            });
        }
      }
    }
  }
}
